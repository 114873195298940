<template>
    <v-layout justify-center>
        <v-responsive max-width="1200px" width="100%" height="100%" class="shop-product-view">

            <v-row class="px-1" align="center">
                <v-col cols="auto" class="headline">관리자 페이지</v-col>
            </v-row>
            
        </v-responsive>
    </v-layout>
</template>

<script>

export default {
}
</script>